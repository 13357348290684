<template>
  <div class="component">
    <van-action-sheet v-model="show" title="添加抽奖活动">
      <div class="content" style="color: #818181;">

        <div class="user-list">
          <van-radio-group icon-size="15px" v-model="items.radio">
            <van-radio shape="square" v-for="(item,index) in lottyList.list" :key="index" :name="item">
              <div class="loettl">
                <img src="../../../assets/images/lottery-default-cover.png" alt="">
                <div>
                  <span>{{item.name}}</span>
                  <span>{{item.description}}</span>
                </div>
              </div>
            </van-radio>
          </van-radio-group>
        </div>

        <van-button @click="determine" block style="width: 90%;margin: 0 auto;margin-top: 10px;margin-bottom: 10px"
                    type="info">确定
        </van-button>
      </div>
    </van-action-sheet>
  </div>
</template>

<script>
import {lottery, store, upload} from '../../../api/contactWelcome'

export default {
  data() {
    return {
      show: false,
      lottyList: [],
      index: null,
      items: {
        title: '',
        radio:""
      }
    }
  },
  watch: {},
  methods: {
    $show(data) {
      this.show = true
      this.items = {
        title: '',
        radio:""
      }
      lottery({status: 1}).then(res=>{
        this.lottyList = res.data
        if(data.edit){
          this.data = data
          this.items = data.content
          this.index = data.index
        }else{
          this.data = data
        }
      })
    },
    determine(e) {
      if (!this.items.radio) {
        this.$message.warning('请选择抽奖活动');
        this.show = false
        return
      }
      const data = {
        content: {
          title: this.items.radio.name,
          id:this.items.radio.id,
          radio: this.items.radio,
          type: '【抽奖】：',
          types: 2
        },
        data:this.data,
      }
      this.$emit("change", data)
      this.show = false
    },
  }
}
</script>

<style lang="less" scoped>
/deep/ .van-empty__image {
  width: 129px !important;
  height: 115px !important;
}

/deep/ .van-action-sheet__header {
  font-weight: bolder;
}
/deep/.van-radio__label{
  width: 100%;
  .loettl{
    display: flex;
    /* flex-direction: column; */
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    padding: 10px;
    img{
      width: 35px;
      height: 35px;
    }
    div{
      display: flex;
      flex-direction: column;
      margin-left: 10px;
      span:nth-child(1){
        font-size: 14px;
        color: #444;
      }
      span:nth-child(2){
        color: #999;
        font-size: 12px;
      }
    }
  }
}
.component {
  .content {
    padding: 0;
    .ttips {
      /deep/ .van-icon {
        font-size: 14px !important;
        color: #1890ff !important;
        margin-right: 5px !important;
      }

      font-size: 12px;
      padding: 5px 14px;
      background: #effaff;
      margin: 7px 7px;
    }

    .user-list {
      height: 275px;
      overflow: auto;
      padding: 0 10px;
      .user {
        display: flex;
        align-items: center;
        justify-content: space-between;

        div {
          font-size: 14px;
          color: #252528;

          img {
            width: 46px;
            height: 46px;
            margin-right: 6px;
          }
        }
      }

      .active-background {
        background: #f6fbff !important;
      }
    }
  }
}
</style>
