<template>
  <div class="component">
    <van-action-sheet v-model="show" title="添加图片素材">
      <div class="content" style="color: #818181;">

        <div class="user-list">
          <van-field required :error="!items.title"v-model="items.title" input-align="right" label="链接标题：" placeholder="请输入链接标题"/>
          <van-field required :error="!items.description"v-model="items.description" input-align="right" label="链接描述：" placeholder="请输入链接描述"/>
          <van-field required :error="!items.imageLink" v-model="items.imageLink" input-align="right" label="链接地址：" placeholder="请输入链接地址"/>
          <van-cell required title="链接封面">
            <van-uploader :after-read="afterRead" v-model="items.fileList" :max-size="2000 * 1024" @oversize="onOversize"
                          multiple :max-count="1"/>
          </van-cell>
        </div>

        <van-button @click="determine" block style="width: 90%;margin: 0 auto;margin-top: 10px;margin-bottom: 10px"
                    type="info">确定
        </van-button>
      </div>
    </van-action-sheet>
  </div>
</template>

<script>
import {store, upload} from '../../../api/contactWelcome'

export default {
  data() {
    return {
      show: false,
      items: {
        title: '',
        description: '',
        imageLink: '',
        fileList: [],
      }
    }
  },
  watch: {},
  methods: {
    $show(data) {
      this.show = true
      this.items = {
        title: '',
        description: '',
        imageLink: '',
        fileList: [],
      }
      if(data.edit){
        this.data = data
        this.items = data.content
        this.index = data.index
      }else{
        this.data = data
      }
    },
    determine(e) {
      if (this.items.fileList.length <=0) {
        this.$message.warning('请选择链接封面');
        this.show = false
        return
      }else{
        store({
          content: {
            TextTitle: this.items.title,
            description: this.items.description,
            imageLink: this.items.imageLink,
            imagePath: this.items.fileList[0].path
          },
          mediumGroupId: 0,
          type:3
        }).then(res=>{
          const data = {
            content: {
              title: this.items.title,
              description: this.items.description,
              imageLink: this.items.imageLink,
              fileList: this.items.fileList,
              imagePath: this.items.fileList[0].path,
              id:res.data.id,
              type: '【链接】：',
              types: 1
            },
            data:this.data,
          }
          this.$emit("change", data)
          this.show = false
        })
      }
    },
    // 文件大小
    onOversize(file) {
      this.$message.warning('文件大小不能超过 2MB');
    },
    // 上传文件
    afterRead(file){
      var formData = new FormData();
      formData.append('file', file.file);
      upload(formData).then(res=>{
        // this.items.title = res.data.name
        this.items.fileList[0] = {
          url:  res.data.fullPath,
          path: res.data.path
        }
      })
    }

  }
}
</script>

<style lang="less" scoped>
/deep/ .van-empty__image {
  width: 129px !important;
  height: 115px !important;
}

/deep/ .van-action-sheet__header {
  font-weight: bolder;
}

.component {
  .content {
    padding: 0;

    .user-list {
      height: 275px;
      overflow: auto;

      .user {
        display: flex;
        align-items: center;
        justify-content: space-between;

        div {
          font-size: 14px;
          color: #252528;

          img {
            width: 46px;
            height: 46px;
            margin-right: 6px;
          }
        }
      }

      .active-background {
        background: #f6fbff !important;
      }
    }
  }
}
</style>
