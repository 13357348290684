import request from "../plugins/axios";

export function upload(data) {
    return request({
        url: '/common/upload',
        method: 'POST',
        data
    })
}
export function store(data) {
    return request({
        url: '/medium/store',
        method: 'POST',
        data
    })
}
export function lottery(params) {
    return request({
        url: '/lottery/index',
        method: 'GET',
        params
    })
}
export function greetingS(data) {
    return request({
        url: '/greeting/store',
        method: 'POST',
        data
    })
}
export function greetingL(params) {
    return request({
        url: '/greeting/index',
        method: 'GET',
        params
    })
}
export function destroy(data) {
    return request({
        url: '/greeting/destroy',
        method: 'DELETE',
        data
    })
}
export function greetingDe(params) {
    return request({
        url: '/greeting/show',
        method: 'GET',
        params
    })
}
export function greetingDeUpdate(data) {
    return request({
        url: '/greeting/update',
        method: 'PUT',
        data
    })
}

