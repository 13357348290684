<template>
  <div class="component">
    <van-action-sheet v-model="show" title="周期时间选择">
      <div class="content" style="color: #818181;">
        <div class="user-list">
          <van-checkbox-group  icon-size="15px" v-model="radio">
            <van-cell-group>
              <van-cell
                  v-for="item in week" :key="item.id"
                  :title="item.name"
              >
                <template #right-icon>
                  <van-checkbox shape="square" :name="item" ref="choseRadio" />
                </template>
              </van-cell>
            </van-cell-group>
          </van-checkbox-group>
        </div>
        <van-button @click="determine" block style="width: 90%;margin: 0 auto;margin-top: 10px;margin-bottom: 10px" type="info">确定</van-button>
      </div>
    </van-action-sheet>
  </div>
</template>

<script>
export default {
  data() {
    return {
      show:false,
      radio: [],
      week:[
        {
          name: '星期天',
          id: 0
        },
        {
          name: '星期一',
          id: 1
        },
        {
          name: '星期二',
          id: 2
        },
        {
          name: '星期三',
          id: 3
        },
        {
          name: '星期四',
          id: 4
        },
        {
          name: '星期五',
          id: 5
        },
        {
          name: '星期六',
          id: 6
        }
      ],
      index: ''
    }
  },
  watch: {},
  methods: {
    $show(data,e){
        this.show = true
        // this.radio = data
        this.index = e
      if(data.length > 0){
        this.editCOde(data)
      }
    },
    determine (e) {
      if (!this.radio) {
        Toast.fail('请选择客户');
      }
      const data = this.radio
      const info = {
        name: this.index
      }
      this.$emit("change",data,info)
      this.show = false
      this.radio = []
    },
    editCOde(data){
      this.$nextTick(() => {
        data.forEach((item,index)=>{
          this.week.forEach((its,ins)=>{
            if(item.id == its.id){
              this.$refs.choseRadio[ins].toggle(true)
            }
          })
        })
      })
    },
  }
}
</script>

<style lang="less" scoped>
/deep/ .van-empty__image {
  width: 129px !important;
  height: 115px !important;
}
/deep/.van-cell{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.component {
  .content{
    padding: 0;
    .user-list{
      height: 275px;
      overflow: auto;
      .user{
        display: flex;
        align-items: center;
        justify-content: space-between;
        div{
          font-size: 14px;
          color: #252528;
          img{
            width: 46px;
            height: 46px;
            margin-right: 6px;
          }
        }
      }
      .active-background{
        background: #f6fbff !important;
      }
    }
  }
}
</style>
